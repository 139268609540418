table {
    width: 100%;
    border-collapse: collapse;
}
table th,
table td {
    padding: 10px;
    font-size: 0.85em;
    text-align: left;
    border-bottom: 1px solid#eee;
}
table th {
    font-size: 0.8em;
    font-weight: normal;
    color: #666;
}

table th a {
    color: var(--main-button-color);
    text-decoration: underline;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-right: 20px;
}
table th a:hover {
    text-decoration: none;
}
table th a::after,
table th a::before {
    width: 0;
    height: 0;
    content: " ";
    display: block;
    position: absolute;
    right: 0;
}
table th a::before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #bbb;
    top: 0;
}
table th a::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #bbb;
    bottom: 0;
}
.sorted {
    background: #f5f5f5;
}
.sorted a::after {
    border-top-color: #000;
}
table .bubble {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    float: right;
    min-width: 70px;
}
.red.bubble {
    background: #f05050;
}
.bubble.orange {
    background: #ce861a;
}
.green.bubble {
    background: #15863d;
}
table tr.show {
    display: table-row;
}
table tr.hide {
    display: none;
}
