.booking-page {
    min-height: calc(100vh - 120px);
    display: flex;
}
.booking-page > * {
    width: 50%;
}
.booking-date {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    background: #fcfcfc;
}
.booking-list {
    display: flex;
    min-height: calc(100vh - 80px);
    justify-content: space-between;
}
.booking-list .bookings {
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 80px);
}
.booking-list .alertandreminders {
    width: 300px;
    border-left: 1px solid #ddd;
    padding: 20px 0 20px 20px;
    overflow: auto;
    max-height: calc(100vh - 80px);
}
.booking-list-heading {
    width: 100%;
    width: 100%;
    position: sticky;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #eee;
    z-index: 1;
}
.booking-list-heading-inner {
    max-width: 1024px;
    padding: 10px 30px;
    display: flex;

    font-size: 0.85em;
    height: 60px;
    align-items: center;
}
.booking-list-heading-inner > * {
    margin-right: 40px;
}
.filter-popup-container {
    position: relative;
}
.filter-popup {
    position: absolute;
    background: #fff;
    padding: 0;
    /* display: none; */
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
    z-index: 100;
    border-radius: 3px;
    right: 0px;
    top: 150%;
    min-width: 300px;
    border: 1px solid #ddd;
}
.booking-filter > div .value {
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 5px;
}
.booking-filter > div .value::after {
    content: " ";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin: 2px 0 0 4px;
    border-top: 5px solid #000;
}
.booking-filter > div .value:hover {
    text-decoration: none;
}
.filter-popup > div > a {
    display: block;
    padding: 15px 20px;
    font-size: 1.15em;
    border-bottom: 1px solid #eee;
    color: #000;
    text-decoration: none;
}
.filter-popup > div > a:hover {
    background: #fafafa;
}
.filter-search {
    background: #fafafa;
    padding: 20px;
}
.filter-search input {
    border: 1px solid #ddd;
    margin: 5px 0;
    padding: 10px;
    width: 100%;
}
.filter-search span {
    display: block;
    padding-top: 10px;
}
.filter-search-bu {
    padding-top: 10px;
}
.booking-filter {
    font-size: 0.85em;
    display: flex;
    align-items: center;
}
.booking-filter > span {
    color: #666;
}
.stay-details {
    max-width: 402px;
}
.stay-details label {
    display: block;
    padding-bottom: 5px;
    font-size: 0.9em;
}
.check-in-date span:first-child {
    padding: 0 5px 0 0;
}
.check-in-date span:last-child {
    padding: 0 0 0 5px;
}
#nNights {
    padding: 5px 10px 10px 5px;
    font-size: 0.8em;
    min-height: calc(1em + 25px);
    line-height: 1em;
    text-align: right;
    font-weight: bold;
}

.book-package {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.package-select {
    max-width: 400px;
}
.package-select .illustration {
    height: 100px;
    margin-bottom: 10px;
    border-radius: 15px;
    position: relative;
    border: 1px solid #ebeef3;
}
.select-p {
}
.select-p input {
    visibility: hidden;
    position: absolute;
}
.select-p input + i {
    width: 28px;
    height: 28px;
    border: 2px solid var(--main-button-color);
    display: block;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}
.select-p input:checked + i::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    background: var(--main-button-color);

    transform: translate(-50%, -50%);
    position: absolute;
}
.package-select h3 {
    font-size: 1em;
    font-weight: 600;
}
.package-select h4 {
    font-weight: 600;
    font-size: 0.85em;
}
.package-select ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;
}
.package-select em {
    font-style: normal;
    font-size: 0.8em;
    display: block;
    padding-top: 3px;
}
.package-select ul li {
    margin: 5px 0;
    width: calc(50% - 20px);
    padding: 10px 0;
    border-bottom: 1px solid #ebeef3;
}
.package-select ul li img {
    width: 100%;
    border-radius: 7px;
}
.package-select p {
    font-size: 0.75em;
    margin: 0;
    color: #666;
    padding: 5px 0 10px;
}
.p-details {
    padding: 0 10px;
}
.hp.illustration {
    background: url(/images/packages/hp.png) no-repeat right center;
    background-size: contain;
}
.dp.illustration {
    background: url(/images/packages/dp.png) no-repeat center bottom;
    background-size: contain;
}
.dc.illustration {
    background: url(/images/packages/dc.png) no-repeat center bottom;
    background-size: contain;
}
.rb.illustration {
    background: url(/images/packages/rm.svg) no-repeat center bottom;
    background-size: contain;
}
.package-dropdown {
    padding-bottom: 30px;
    display: none;
}
.packages-select {
    padding-bottom: 30px;
}
@media screen and (max-width: 800px) {
    html .stay-details {
        max-width: 100%;
    }
    .package-select {
        max-width: 100%;
    }
    .package-select h3 {
        padding: 20px 20px 10px;
    }
    .book-package {
        padding: 0;
    }
    .booking-page {
        flex-direction: column;
        background: #fcfcfc;
    }
    .booking-page > * {
        width: 100%;
    }
    .package-select ul {
        flex-wrap: nowrap;
        overflow: auto;
        padding: 0 10px;
    }
    .package-select ul li {
        flex-shrink: 0;
        width: 60vw;
        border: none;
        margin: 5px 10px;
        position: relative;
        background: #fff;
        border-radius: 15px;
    }
    .package-select .illustration {
        position: static;
        background-color: transparent;
        border: none;
    }
    .package-select p {
        display: none;
    }
    .select-p input + i {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        transform: none;
        background: none;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        border: 1px solid #eee;
    }
    .select-p input:checked + i::before {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        left: 100%;
        border: 2px solid var(--main-button-color);
        background: none;
        left: 0;
        top: 0;
        transform: none;
    }
    .select-p input + i::after {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--main-button-color);
        content: " ";
        left: 10px;
        top: 10px;
    }
    .select-p input:checked + i::after {
        background: var(--main-button-color);
    }
    .p-details {
        padding: 0 20px 10px;
    }
    .booking-date {
        background: none;
        padding-top: 30px;
    }
}
.popup-container {
    /* display: none; */
}
.popup-dimmer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
}
.popup {
    position: fixed;
    max-width: 400px;
    left: 50%;
    top: 50%;
    width: calc(100% - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.popup-footer {
    padding: 10px 0 0;
    display: flex;
    justify-content: space-between;
}
.popup-warning {
    padding: 10px;
    background: #ffffe6;
    border-radius: 5px;
}
.popup-message {
    padding: 10px;
    font-weight: bold;
}
.room-count em {
    display: block;
    font-style: normal;
    color: #999;
    font-size: 0.75em;
    padding-top: 5px;
}

.added-form-row > * {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

*.room-c-name {
    vertical-align: middle;
    white-space: nowrap;
    font-size: 0.8em;

    text-align: right;
}

.detailed-package .listofdetails {
    font-size: 1em;
    color: #000;
}
.detailed-package .listofdetails dl dt svg {
    width: 32px;
    height: 32px;
}
.detailed-package .listofdetails dl {
    padding: 0 0 0 50px;
}
.detailed-package h2 {
    padding-bottom: 10px;
}
[data-opt-container] {
    display: none;
}
.detailed-package {
    width: 100%;
    max-width: 600px;
}
.moreinfo-aboutpackage {
    padding-top: 5px;
    font-size: 0.8em;
    color: var(--main-button-color);
    text-decoration: underline;
    display: none;
}
.close-packagedetails {
    display: none;
}
.override-book-package {
    display: block !important;
}
@media screen and (max-width: 800px) {
    .book-package {
        display: none;
    }
    .book-package.on {
        display: block;
    }
    .moreinfo-aboutpackage {
        display: block;
    }
    .book-package {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background: rgba(0, 0, 0, 0.08);
        padding: 10px;

        z-index: 100;
    }

    .detailed-package {
        left: 10px;
        top: 10px;
        width: calc(100vw - 20px);
        height: calc(100vh - 20px);
        overflow: auto;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        padding: 20px;
        position: fixed;
        z-index: 102;
        background: #fff;
        border-radius: 10px;
    }
    .close-packagedetails {
        position: relative;
        display: block;
    }
    .close-packagedetails a {
        position: fixed;
        right: 20px;
        top: 20px;
        background: rgba(100%, 100%, 100%, 0.5);
        width: 32px;
        height: 32px;
        border: 1px solid #ddd;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 105;
    }
}
.addcode {
    padding-bottom: 30px;
}
.package-dropdown .checkbox {
    margin-top: -20px;
}
[data-opt-container="dp-o"] .checkbox {
    padding-bottom: 0;
}
.howdidyoufindus {
    padding-bottom: 30px;
}
