.booking-list {
    display: flex;
    min-height: calc(100vh - 80px);
    justify-content: space-between;
}
.booking-list .bookings {
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 80px);
}
.booking-list .alertandreminders {
    width: 300px;
    border-left: 1px solid #ddd;
    padding: 20px 0 20px 20px;
    overflow: auto;
    max-height: calc(100vh - 80px);
}
.alertandreminders h3 {
    font-size: 1em;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.alertandreminders h3 .bubble {
    background: #222;
    color: #fff;
    margin-left: 5px;
    display: flex;
    font-size: 0.8em;
    align-items: center;
    padding: 5px;
    border-radius: 12px;
    height: 24px;
    min-width: 24px;
    justify-content: center;
}
a {
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
}
a:hover {
    text-decoration: none;
}
.alertandreminders dl {
    font-size: 0.85em;
}
.alertandreminders div strong {
    display: block;
    font-size: 1.5em;
}

.alertandreminders div.task-title a {
    font-size: 0.8em;
}
.task > div {
    margin: 0;
    padding: 15px 20px 15px 20px;

    font-size: 0.8em;
    position: relative;
}
.completed-task .task {
    color: #666;
}
.task > div:not(.task-title)::after {
    position: absolute;
    width: calc(100% - 20px);
    height: 1px;
    background: #eee;
    bottom: -1px;
    right: 0;
    content: " ";
}
.task > div:last-child::after {
    display: none;
}
.alertandreminders div.task-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 10px 0px 0;
    font-size: 0.85em;
    border-bottom: none;
}
.task > div input {
    position: absolute;
    left: 0;
    top: 15px;
    margin: 0;
}
.completed-link {
    display: none;

    align-items: center;
}
.completed-link .bubble {
    background: var(--main-button-color);
    color: #fff;
    margin-left: 5px;
    display: flex;
    font-size: 0.8em;
    align-items: center;
    padding: 2px;
    border-radius: 8px;
    height: 16px;
    min-width: 16px;
    justify-content: center;
}
.has-completed-task .completed-link {
    display: flex;
}
.has-completed {
    display: none;
}
.show-completed-task dd {
    display: none;
}
.show-completed-task dd.has-completed {
    display: block !important;
    background: #fafafa;
    color: #666;
}
.show-active-link {
    display: none;
}
.show-completed-task .show-active-link {
    display: flex;
    font-weight: bold;
    text-decoration: none;
    background: var(--main-button-color);
    color: #fff;
    padding: 4px 10px;
    border-radius: 3px;
    margin-right: 10px;
}
.show-completed-task .completed-link {
    display: none;
}
.load-more {
    text-align: center;
    padding-top: 20px;
}
.load-more a {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #666;
    font-size: 0.8em;
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
}
.load-more a:hover {
    background: #f5f5f5;
}
.completed-task-heading {
    padding: 20px 0 5px;
    font-size: 0.85em;
    font-weight: 600;
    cursor: pointer;
    position: relative;
}
.completed-task-heading::after {
    content: " ";
    height: 5px;
    width: 5px;
    border: 2px solid #000;
    border-width: 2px 2px 0 0;
    position: absolute;
    transform: rotate(-45deg);
    right: 30px;
    top: 50%;
}
.completed-task-heading.up::after {
    transform: rotate(135deg);
}
.task-box {
    padding-bottom: 40px;
}
.booking-list-heading {
    width: 100%;
    width: 100%;
    position: sticky;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #eee;
    z-index: 1;
}
.booking-list-heading-inner {
    max-width: 1024px;
    padding: 10px 30px;
    display: flex;

    font-size: 0.85em;
    height: 60px;
    align-items: center;
}
.booking-list-heading-inner > * {
    margin-right: 40px;
}
.searchbooking input {
    padding: 10px 10px 10px 30px;
    border: none;
    background: url(/images/icons/search-icon.svg) no-repeat 0 center;
    font-size: 0.85em;
}
.booking-status {
    display: flex;
}
.booking-status label {
    margin-right: 10px;
    display: flex;
    padding-left: 20px;
    position: relative;
    align-items: center;
    font-size: 0.85em;
}
.booking-status input {
    margin: 0;
}
.booking-status label input {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.filter-popup-container {
    position: relative;
}
.filter-popup {
    position: absolute;
    background: #fff;
    padding: 0;
    display: none;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
    z-index: 100;
    border-radius: 3px;
    right: 0px;
    top: 250%;
    min-width: 300px;
    border: 1px solid #ddd;
}
.booking-filter > div .value {
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 5px;
}
.booking-filter > div .value::after {
    content: " ";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin: 2px 0 0 4px;
    border-top: 5px solid #000;
}
.booking-filter > div .value:hover {
    text-decoration: none;
}
.filter-popup > div > a {
    display: block;
    padding: 15px 20px;
    font-size: 1.15em;
    border-bottom: 1px solid #eee;
    color: #000;
    text-decoration: none;
}
.filter-popup > div > a:hover {
    background: #fafafa;
}
.filter-search {
    background: #fafafa;
    padding: 20px;
}
.filter-search input {
    border: 1px solid #ddd;
    margin: 5px 0;
    padding: 10px;
    width: 100%;
}
.filter-search span {
    display: block;
    padding-top: 10px;
}
.filter-search-bu {
    padding-top: 10px;
}
.booking-filter {
    font-size: 0.85em;
    display: flex;
    align-items: center;
}
.booking-filter > span {
    color: #666;
}
.booking-list-inner {
    padding: 30px;
    max-width: 1400px;
    margin: 0 auto;
}
.bookings h2 {
    /* padding: 30px 30px 10px; */
}
.canceled td {
    text-decoration: line-through;
}
.canceled > td > a {
    color: red;
}
tr.canceled {
    display: none;
}
.booking-details-page {
    min-height: 500px;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0;
}
.booking-dates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    padding: 20px 0 0;
}
.contact-and-room-details {
    padding: 0 20px;
}
.contact-and-room-details dl {
    display: flex;
    flex-direction: column;
    margin: 0;
}
.contact-and-room-details dt {
    font-weight: bold;
}
.contact-and-room-details dd {
    display: flex;

    font-size: 0.85em;
    padding: 10px 0 0;
    margin: 0;
}
.contact-and-room-details dd label {
    display: flex;
    width: 100px;
    color: #666;
}
.contact-and-room-detailsdd strong {
    color: #212121;
    font-weight: normal;
}
.contact-and-room-details h4 {
    font-weight: bold;
}
.contact-and-room-details h4 + dl {
    margin: 0;
}
.room-details-heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 50px 10px 20px;
    background: #fafafa;
    position: relative;
    cursor: pointer;
}
.room-details-heading::after {
    content: " ";
    position: absolute;
    right: 20px;
    border: 2px solid #000;
    width: 7px;
    height: 7px;
    border-width: 2px 2px 0 0;
    transform: rotate(135deg);
}
.room-details-box {
    border: 1px solid #ddd;
    margin: 10px 0 20px;
}
.room-details-heading ul {
    margin: 5px 0 0;
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
    .room-details-heading ul {
        flex-direction: column;
    }
}
.room-details-heading ul li {
    list-style: none;
    margin-right: 20px;
    font-size: 0.85em;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.room-details-heading ul li::before {
    content: " ";
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    margin-right: 5px;
}
.room-details .guest {
    padding: 20px;
    font-size: 0.85em;
}
.contact-and-room-details em {
    font-size: 11px;
    background: rgba(0, 0, 0, 0.1);
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    padding: 0.4em 0.4em 0.4em;
    font-weight: bold;
    line-height: 1;
    color: #555;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    border: 1px solid #ddd;
}
.room-details {
    display: none;
}
.on .room-details {
    display: block;
    border-top: 1px solid #eee;
}
.on .room-details-heading::after {
    transform: rotate(-45deg);
    margin-top: 5px;
}
.contact-and-room-details h3 {
    margin-top: 40px;
    font-size: 1.5em;
}
.room-details-heading strong {
    font-size: 0.85em;
}
.booking-details-page-head {
    padding: 30px 20px 20px;
}
.booking-details-page .t-tabs a {
    color: #999;
    border-right: 0;
    padding-bottom: 10px;
    color: var(--main-button-color);
}
.booking-details-page .t-tabs a.active {
    background: #fff;
    color: #000;
}

.t-tabs a .bubble {
    position: absolute;
    bottom: 5px;
    line-height: 1.2em;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    background: #f00;
    color: #fff;
    padding: 0 5px;
    border-radius: 4px;
}
.transport-details h4 {
    font-size: 0.85em;

    padding-top: 10px;
    padding-bottom: 10px;
}
.transport-details h4 + ul {
    font-size: 0.85em;
    margin: 0;
}
.transport-details h4 + ul li {
    padding-bottom: 5px;
}
.transport-details {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    margin-top: 40px;
}
.transport-details > div {
    width: 50%;
}
.transport-details > div:last-child {
    border-left: 1px solid #ddd;
    padding-left: 20px;
}
.transport-details > div:first-child {
    padding-right: 20px;
}
.transport-details h4 + ul {
    color: #666;
}
.transport-details h4 + ul strong {
    font-weight: normal;
    color: #000;
}
.contact-and-room-details .transport-details h3 {
    margin-top: 0;
    padding-bottom: 20px;
}

@media screen and (max-width: 700px) {
    .transport-details {
        display: block;
        margin-top: 0;
    }
    .contact-and-room-details .transport-details h3 {
        margin-top: 40px;
        padding-bottom: 0;
    }

    .transport-details > div:last-child,
    .transport-details > div:first-child {
        border: none;
        padding: 0;
        width: 100%;
    }
}
.invoice-box {
    padding: 20px;
    max-width: 500px;
}
.booking-details-page .payment-container {
    padding-top: 40px;
}
.file {
    margin-bottom: 10px;
}
.file img {
    max-width: 100%;
}
.booking-details-page-head .bu {
    white-space: nowrap;
    margin-left: 50px;
}

.booking-details-page .price-summery-box dt,
.booking-details-page .price-summery-box dd {
    border-top: none;
    padding: 0 0 5px 0;
}
.booking-history {
    margin: 40px 0;
}
.booking-history li {
    padding-bottom: 20px;
}
