.packages {
    background: #f2f2f2;
    padding: 10px;
    min-height: calc(100vh - 80px);
}
.p-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.p-list > li {
    padding: 20px;
    margin: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    min-width: 300px;
    cursor: pointer;
}
.p-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.p-heading h2 {
    font-size: 1.25em;
}
.p-live-status {
    font-size: 0.85em;
    display: flex;
    align-items: center;
}
.p-live-status span {
    display: inline;
    padding: 0.2rem 0.6rem 0.3rem;

    font-size: 0.7rem;

    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background: #fff;
    margin: 0 5px 5px 0;
    background: #f2f2f2;
}
.p-perc {
    display: flex;
    align-items: center;
    padding-top: 20px;
    font-size: 0.8em;
}
.p-perc img {
    margin-right: 10px;
}
.p-list > li.disabled {
    background: #f5f5f5;
}

.disabled .p-live-status span {
    background: #dedede;
}
.disabled .p-heading h2 {
    color: #999;
}
.p-list > li:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
.p-header {
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
    align-items: center;
    padding: 10px;
}
.p-filter {
    display: flex;
}
.p-filter label {
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.p-share-group {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.p-share-group select {
    margin-left: 10px;
}
.package-popup {
    width: 50%;
    position: fixed;
    left: 50%;
    top: 0;
    height: 100%;
    background: #fff;
    width: 50%;
    z-index: 9997;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.package-popup {
    /* display: none; */
    padding: 20px;
}
.package-popup-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.package-popup-heading .bu {
    min-width: 20px;
}
.package-popup .listofdetails {
    font-size: 1em;
    line-height: 1.5em;
    color: #222;
}
.package-popup .listofdetails dl dt svg {
    width: 32px;
    height: 32px;
}
.package-popup .listofdetails dl {
    padding-left: 50px;
}
.create-package {
    margin: auto;

    padding: 20px;
}

.package-action {
    display: flex;
}
.package-action .bu {
    margin-left: 10px;
}
.Package-details-form {
    display: flex;
    justify-content: space-between;
}
.Package-details-form > * {
    width: calc(25% - 10px);
}
.add-package-group {
    color: var(--main-button-color);
    font-size: 0.8em;
    text-decoration: underline;
    cursor: pointer;
}
.add-package-group:hover {
    text-decoration: none;
}
.select-package-group {
    padding-top: 20px;
    display: none;
}

.add-another-booking-period.small-bu {
    margin: 0 10px 0 0;
}
input[type="range"] {
    width: 210px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
    outline: none;
}
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 200px;
    height: 1px;
    background: #003d7c;
}

input[type="range"]:nth-child(2)::-webkit-slider-runnable-track {
    background: none;
}

input[type="range"]::-webkit-slider-thumb {
    position: relative;
    height: 15px;
    width: 15px;
    margin-top: -7px;
    background: #fff;
    border: 1px solid #003d7c;
    border-radius: 25px;
    z-index: 1;
}

input[type="range"]:nth-child(1)::-webkit-slider-thumb {
    z-index: 2;
}
.date-range {
    color: var(--main-button-color);
}
.rangeslider {
    position: relative;
    height: 60px;
    width: 210px;
    display: inline-block;
    margin-top: -5px;
    margin-left: 20px;
}
.rangeslider input {
    position: absolute;
}
.rangeslider {
    position: absolute;
}

.rangeslider span {
    position: absolute;
    margin-top: 30px;
    left: 0;
}

.rangeslider .right {
    position: relative;
    float: right;
    margin-right: -5px;
}
.slider-months {
    display: flex;
    justify-content: space-between;
}
.slider-months > * {
    width: 8.33%;
    text-align: center;
    padding-bottom: 10px;
    font-size: 11px;
    border-left: 1px solid #ddd;
}
.ui-widget-content {
    background: #e2e8f9;
    height: 2px;
    position: relative;
    border: none;
    margin: 20px 0;
}
.ui-slider .ui-slider-range {
    position: absolute;
    background: var(--main-button-color);
    height: 100%;
    display: block;
    border: 0;
    background-position: 0 0;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    position: absolute;
    height: 16px;
    width: 16px;
    border: 2px solid var(--main-button-color);
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
    margin-top: -7px;
    margin-left: -8px;
}

fieldset legend .date-range {
    font-weight: 400;
    color: #666;
}

fieldset legend .date-range strong {
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
}
fieldset legend .date-range strong:hover {
    text-decoration: none;
}

.placholder-text {
    padding: 20px;
    text-align: center;
    font-size: 0.8em;
    opacity: 0.5;
}

.packageNights {
    display: flex;
    align-items: flex-end;
}
.addpackageOption {
    width: 42px;
    padding-left: 10px;
}
.addpackageOption .bu {
    min-width: 30px;
    height: 30px;
}
.packageNightsInput input,
.package-name-input input {
    margin-top: 10px;
}
.package-includes-excludes {
    justify-content: space-between;
    display: none;
}
.package-includes-excludes.on {
    display: flex;
}

.package-includes-excludes > * {
    width: 100%;
}
.packageNightsInput {
    margin: 0;
    padding: 0 0 0 15px;
    width: 100%;
}
.packageNightsInput li > div {
    display: flex;
}
.packageNightsInput li > div input[type="number"] {
    width: 75px;
    margin-left: 10px;
}
.packageIncludeData {
    display: flex;
}
.packageIncludeData > * {
    width: 100%;
    border-left: 1px solid #ddd;
    padding: 0 20px;
}
.packageIncludeData > *:first-child {
    padding-left: 0;
    border-left: 0;
}
.packageIncludeData > *:last-child {
    padding-right: 0;
}
.pName-header {
    color: #da7800;
}
.package-include-data,
.roomspopup {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 600px;

    background-color: #fff;

    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
    z-index: 10005;

    overflow: auto;
}
.package-include-data dt {
    font-size: 0.85em;
    padding-bottom: 5px;
    font-size: 0.8em;
    padding-bottom: 5px;
    font-weight: 600;
}
.package-include-data dl {
    margin: 10px 0 30px;
    background-color: #f9fbff;
    border: 1px solid #dbe0e8;
    border-bottom: none;
}
.package-include-data dt,
.package-include-data dd {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid #dbe0e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.package-include-data dd .checkbox {
    margin: 0;
}
.package-include-data dt {
    background: #edf0f7;
}

.package-include-data dd input[type="number"] {
    width: 50px;
    margin: 0 10px 0;
}
.add-package-include-data,
.rooms-price-list a {
    margin: 10px 0 20px;
    font-size: 0.8em;
    text-decoration: underline;
    color: var(--main-button-color);
    display: inline-block;
    cursor: pointer;
}
.add-package-include-data:hover,
.rooms-price-list a:hover {
    text-decoration: none;
}
.amenities-heading,
.rooms-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 30px;
    border-bottom: 1px solid #eee;
}
.amenities-list,
.rooms-listing {
    overflow: auto;
    padding: 20px 30px;
    height: calc(100vh - 80px);
}
.ammList {
    font-size: 0.8em;
}
.ammList-item {
    padding-bottom: 5px;
    padding-left: 15px;
    position: relative;
}
.ammList-item::before {
    content: " ";
    position: absolute;
    left: 3px;
    top: 8px;
    width: 5px;
    height: 5px;
    background: var(--main-button-color);
    border-radius: 50%;
}
.ammList-heading {
    font-size: 1em;
    padding-bottom: 5px;
}
.checkbox input.optionalAmm {
    margin-left: auto;
}
dd.activated .switch {
    visibility: hidden;
}
.optional-ammList-heading {
    padding-top: 20px;
}
.check-in-period h3 {
    font-size: 1em;
    font-weight: normal;
    position: relative;
}
.check-in-period h3::before {
    position: absolute;
    content: " ";
    width: 5px;
    height: 74px;
    left: -25px;
    top: -19px;
    background: #da7800;
}
.check-in-period h3 > strong {
    font-weight: 600;
}
.check-in-period {
    padding: 20px;
    margin-bottom: 20px;
    background: #fafafa;
}
.dateslider {
    margin: 0 20px 50px;
}
.checkin-date-range strong {
    color: #da7800;
    text-decoration: underline;
    cursor: pointer;
}
.checkin-date-range strong:hover {
    text-decoration: none;
}

.rooms-listing dl {
    margin: 0;
}
.rooms-listing dl dd {
    padding: 10px;
    margin: 0;
}

.checkindateslider .ui-state-default,
.checkindateslider .ui-widget-content .ui-state-default,
.checkindateslider .ui-widget-header .ui-state-default {
    border-color: #da7800;
}
.checkindateslider.ui-slider .ui-slider-range {
    background-color: #da7800;
}
.check-in-period h3.rmmList-heading {
    font-weight: 600;
    padding-bottom: 10px;
}

.rmmList-item {
    display: flex;
    border: 1px solid #ddd;

    justify-content: space-between;
    background: #fff;
    font-size: 0.85em;
    align-items: center;
    margin-bottom: 10px;
}

.rmmList-item > span {
    font-size: 1em;
    font-weight: 600;
    display: block;
    padding: 10px 20px;
}
.rmmList-price {
    width: 60%;
    border-left: 1px solid #ddd;
}
.rmmList-price table {
    border-collapse: collapse;
    width: 100%;
}
.rmmList-price table th,
.rmmList-price table td {
    padding: 10px 20px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
.rmmList-price table th {
    font-size: 0.85em;
    background: #f0f0f0;
}
.rmmList-price table input[type="number"] {
    width: 100px;
}

.rmmList-price table tr:last-child td {
    border-bottom: 0;
}

.bu.add-another-booking-period {
    margin-bottom: 20px;

    margin-left: 10px;
    display: none;
}
.bu.add-another-booking-period.on {
    display: inline-flex;
}
